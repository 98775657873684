import type { Link } from '@dx-ui/osc-link';
import { env } from '../../constants';
import type { TFunction } from 'i18next';

/**
 * Hardcoded links are intended to be temporary until live CMS links are pulled in.
 * _id fields are carried over from a baseline query and are solely used to ensure
 * the footer implementation has something unique for the
 * key fields in the li elements.
 */
export const FOOTER_LINKS = (t: TFunction<'footer'>, locale: string): Link[] =>
  [
    {
      isNewWindow: false,
      label: t('globalPrivacyStatement'),
      url: `${env.OHW_BASE_URL}${locale}/p/global-privacy-statement/`,
    },
    {
      isNewWindow: false,
      label: t('careers'),
      url: 'https://jobs.hilton.com',
    },
    {
      isNewWindow: false,
      label: t('development'),
      url: 'https://www.hilton.com/en/corporate/development/',
    },
    locale === 'en'
      ? {
          isNewWindow: false,
          label: t('creditCards'),
          url: `${env.OHW_BASE_URL}${locale}/hilton-honors/credit-cards/?cid=OH,,GlobalFooterCobrandAllCardsAcqAlwaysOn,MultiBR,,Home,SingleLink,i83471`,
        }
      : null,
    {
      isNewWindow: false,
      label: t('media'),
      url: 'https://stories.hilton.com',
    },
    {
      isNewWindow: false,
      label: t('webAccessibility'),
      url: `${env.OHW_BASE_URL}${locale}/p/accessibility/`,
    },
    {
      isNewWindow: false,
      label: t('siteUsageAgreement'),
      url: `${env.OHW_BASE_URL}${locale}/p/site-usage-agreement/`,
    },
    {
      isNewWindow: false,
      label: t('partnerAccommodations'),
      url: `${env.OHW_BASE_URL}${locale}/p/partner-accommodations-terms/`,
    },
    {
      isNewWindow: false,
      label: t('modernSlaveryAndHumanTrafficking'),
      url: 'https://cr.hilton.com/wp-content/uploads/2021/06/Hilton-Slavery-and-Trafficking-Statement-2020.pdf',
    },
    {
      isNewWindow: false,
      label: t('hhDiscountTermsAndConditions'),
      url: `${env.OHW_BASE_URL}${locale}/hilton-honors/honors-discount-terms/`,
    },
    {
      isNewWindow: false,
      label: t('tourismMediator'),
      url: `${env.OHW_BASE_URL}${locale}/p/tourism-and-travel/`,
    },
    {
      isNewWindow: true,
      label: t('adChoices'),
      url: 'https://optout.aboutads.info/#!/',
    },
  ].filter(Boolean) as Link[];
