import { useMemo, useState } from 'react';
import { MONTH_TABS_IN_DATES_MODAL } from '../../../constants';
import { useGetMVTSelections, useWrappedRouter } from '../../../hooks';
import { MonthDateSelectorComponent } from './search-month-date-selector';

export const useMonthTabsMVT = () => {
  const {
    router: { locale },
  } = useWrappedRouter();
  const language = locale ?? 'en';
  const [enableMonthTabs, setEnableMonthTabs] = useState(false);
  const setEnableMonthTabsMVT = () => {
    setEnableMonthTabs(true);
  };
  const {
    isMVTVariant: { b: isMVTVariant },
    isLoaded,
  } = useGetMVTSelections({
    agentId: MONTH_TABS_IN_DATES_MODAL.agentId,
    isProvisional: enableMonthTabs,
  });

  const isMonthTabsMVT = isLoaded && isMVTVariant;

  const monthDateSelectorProp = useMemo(
    () =>
      isMonthTabsMVT
        ? {
            ...{
              MonthDateSelectorComponent: MonthDateSelectorComponent({ language }),
            },
          }
        : {},
    [isMonthTabsMVT, language]
  );

  return {
    setEnableMonthTabsMVT,
    monthDateSelectorProp,
  };
};
