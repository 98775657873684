import { useRef, useEffect } from 'react';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { getGuestInfo, getPageType } from '../utils';
import { useInitConductrics } from '@dx-ui/framework-conductrics';
import type { GuestQuery } from '@dx-ui/framework-auth-provider';
import { useWrappedRouter } from './use-wrapped-router';

type UseInitializeConductrics = {
  authLoading: boolean;
  guestInfo?: GuestQuery['guest'] | null;
  isAuthenticated: boolean;
  arrivalDate?: string | undefined;
  isReady?: boolean;
  isTwentyPlusResults?: boolean;
};

export const useInitializeConductrics = ({
  authLoading,
  guestInfo,
  isAuthenticated,
  arrivalDate,
  isReady = true,
  //NHCSEARCH-4194 List 10
  isTwentyPlusResults,
}: UseInitializeConductrics) => {
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  // Initialize conductrics (may or may not be needed for all tests, general information)
  const conductricsInitPath = useRef<string>();
  const initConductrics = useInitConductrics();

  const { isSearch, isSearchZero, isHotelsNearMe, isDefaultLocations, isDreams, isResorts } =
    getPageType(router.asPath, queryParameters);

  const routerPath = router.asPath?.split('?')?.[0];

  useEffect(() => {
    if (!authLoading && isReady && routerPath !== conductricsInitPath.current) {
      const traits = [`login:${isAuthenticated ? 'yes' : 'no'}`];

      const inputs = {
        ...(arrivalDate && {
          'lead-time': differenceInCalendarDays(parseISO(arrivalDate), new Date()),
        }),
      };

      if (isSearch || isSearchZero) {
        traits.push('shop-entry:search');
      } else if (isHotelsNearMe || isDefaultLocations) {
        traits.push('shop-entry:default-locations');
      } else if (isDreams || isResorts) {
        traits.push('shop-entry:locations');
      }

      const { hhonors } = guestInfo || {};

      if (hhonors?.summary?.tier) {
        traits.push(`tier:${hhonors.summary.tier.toLowerCase()}`);
      }
      // populate teammember status
      const { isTMTPTeamMember } = getGuestInfo(guestInfo);

      traits.push(`teamMember:${isTMTPTeamMember ? 'yes' : 'no'}`);
      traits.push(`20-results:${isTwentyPlusResults ? 'yes' : 'no'}`);

      initConductrics({ traits, inputs });
      conductricsInitPath.current = routerPath;
    }
  }, [
    isAuthenticated,
    isReady,
    authLoading,
    guestInfo,
    arrivalDate,
    isTwentyPlusResults,
    initConductrics,
    routerPath,
    isSearch,
    isSearchZero,
    isHotelsNearMe,
    isDefaultLocations,
    isDreams,
    isResorts,
  ]);
};
