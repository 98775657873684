import { sendReward } from '@dx-ui/framework-conductrics';
import { format, isEqual } from 'date-fns';
import { useFormContext } from 'react-hook-form';

export const ArrivalDateSpy = ({ arrivalDate }: { arrivalDate?: string }) => {
  const { getValues } = useFormContext();
  const formArrivalDate = format(getValues()?.dates?.arrivalDate, 'yyyy-MM-dd');

  if ((arrivalDate && !isEqual(arrivalDate, formArrivalDate)) || (!arrivalDate && formArrivalDate))
    sendReward('g-edit-dates');
  return null;
};
