import { addYears, eachMonthOfInterval, parseISO } from 'date-fns';

export const getMonthRangeBetweenDates = (startDate?: string, endDate?: string): Date[] | [] => {
  if (!startDate || !endDate) {
    return [];
  }
  const formattedStartDate = parseISO(startDate).toISOString();
  const formattedEndDate = parseISO(endDate).toISOString();

  const interval = {
    start: new Date(formattedStartDate),
    end: new Date(formattedEndDate),
  };

  return eachMonthOfInterval(interval);
};
export const getMinAndMaxArrivalDate = () => {
  const minArrivalDate = new Date().toISOString();
  const maxArrivalDate = addYears(minArrivalDate, 2).toISOString();
  return { minArrivalDate, maxArrivalDate };
};
export const transformTrackingId = (id?: string) => {
  if (!id) {
    return '';
  }

  const transformed_id = id
    .replace(/\-+/g, ' ')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s+/g, '_')
    .replace(/\_+/g, '_')
    .toLowerCase();

  return transformed_id;
};
