import type { ActiveFiltersState, FilterType, TripAdvisorRatings } from './filter.constants';
import type { LocationCategory, Maybe } from '@dx-ui/queries-dx-shop-search-ui';
import { HotelAttributeIds, PRICE_SILDER_MAX, defaultActiveFiltersState } from './filter.constants';
import type { ConversionCurrencyOptions } from '@dx-ui/osc-currency-converter';
import type { QueryParameters } from '../../constants';
import { DEFAULT_LANGUAGE } from '@dx-ui/utilities-dates';

export function updateFilters(
  activeFiltersState: ActiveFiltersState,
  filter: string,
  type: FilterType
) {
  let newActiveFilterState: ActiveFiltersState = defaultActiveFiltersState;

  if (type === 'AMENITIES') {
    const amenityFilters = [...activeFiltersState.amenityFilters];
    const amenityIndex = amenityFilters.indexOf(filter);
    if (amenityIndex !== -1) amenityFilters.splice(amenityIndex, 1);
    newActiveFilterState = {
      ...activeFiltersState,
      amenityFilters,
    };
  } else if (type === 'ATTRIBUTES') {
    const attributeFilters = [...activeFiltersState.attributeFilters];
    const attributeIndex = attributeFilters.indexOf(filter);
    if (attributeIndex !== -1) attributeFilters.splice(attributeIndex, 1);
    newActiveFilterState = {
      ...activeFiltersState,
      attributeFilters,
    };
  } else if (type === 'OPTIONAL') {
    newActiveFilterState = {
      ...activeFiltersState,
      showAvailableHotels: !activeFiltersState.showAvailableHotels,
    };
  } else if (type === 'PRICE' || type === 'POINTS') {
    newActiveFilterState = {
      ...activeFiltersState,
      priceFilter: undefined,
    };
  } else if (type === 'RATINGS') {
    newActiveFilterState = {
      ...activeFiltersState,
      ratingsFilter: filter as TripAdvisorRatings,
    };
  } else if (type === 'BRANDS') {
    const brandFilters = [...activeFiltersState.brandFilters];
    const brandIndex = brandFilters.indexOf(filter);
    if (brandIndex !== -1) brandFilters.splice(brandIndex, 1);
    newActiveFilterState = {
      ...activeFiltersState,
      brandFilters,
    };
  }

  return { newActiveFilterState };
}

export const getFiltersDataForAnalytics = (
  activeFiltersState: ActiveFiltersState,
  initalTAValue?: boolean
) => {
  const selectedFiltersList = activeFiltersState.amenityFilters.concat(
    activeFiltersState.brandFilters
  );
  if (activeFiltersState.showAvailableHotels) selectedFiltersList.push('showAvailableHotels');
  let selectedFilters = selectedFiltersList.join(':');
  const priceSliderValue = activeFiltersState.priceFilter
    ? activeFiltersState.priceFilter.join(':')
    : undefined;
  if (priceSliderValue)
    selectedFilters = selectedFilters ? selectedFilters.concat(':priceSlider') : 'priceSlider';
  const selectedTAFilter =
    initalTAValue && activeFiltersState.ratingsFilter === 'allRatings'
      ? undefined
      : activeFiltersState.ratingsFilter;
  return { selectedFilters, priceSliderValue, selectedTAFilter };
};

export const getActiveFiltersCount = (activeFiltersState: ActiveFiltersState) =>
  activeFiltersState?.amenityFilters?.filter(Boolean).length +
  activeFiltersState?.attributeFilters?.filter(Boolean).length +
  activeFiltersState?.brandFilters?.filter(Boolean).length +
  (activeFiltersState?.showAvailableHotels ? 1 : 0) +
  (isPriceValid(activeFiltersState?.priceFilter?.[0]) ? 1 : 0) +
  (activeFiltersState?.ratingsFilter
    ? activeFiltersState.ratingsFilter === 'allRatings'
      ? 0
      : 1
    : 0);

const isPriceValid = (priceFilter?: number) => Boolean(priceFilter && !(priceFilter === 0));

export const isPriceRangeValid = (priceFilter?: Tuple<2, number>, maxPriceLimit?: number) =>
  Boolean(
    priceFilter &&
      !(
        priceFilter[0] === 0 &&
        priceFilter[1] === (maxPriceLimit ? maxPriceLimit : PRICE_SILDER_MAX)
      )
  );

export function getValidFilters(filters?: string[]): string[] | undefined {
  return filters && filters?.filter(Boolean)?.length > 0 ? filters?.filter(Boolean) : undefined;
}
export function getTARatingValue(filter?: TripAdvisorRatings) {
  return filter && filter !== 'allRatings' ? filter : undefined;
}

export function initializeActiveFiltersState({
  category,
  queryParameters,
  brandCode,
  showSaleFilter,
  sessionFiltersState,
}: {
  queryParameters: QueryParameters;
  category?: Maybe<LocationCategory | 'resort'>;
  brandCode?: string;
  showSaleFilter?: boolean;
  sessionFiltersState?: Partial<ActiveFiltersState>;
  isMvtVariant?: boolean;
}): ActiveFiltersState {
  const { activeFiltersState, datesFlex } = queryParameters;

  const amenityFilters = sessionFiltersState?.amenityFilters?.length
    ? sessionFiltersState.amenityFilters
    : activeFiltersState?.amenityFilters || [];
  const attributeFilters = sessionFiltersState?.attributeFilters?.length
    ? sessionFiltersState?.attributeFilters
    : activeFiltersState?.attributeFilters || [];
  const brandFilters = sessionFiltersState?.brandFilters?.length
    ? sessionFiltersState.brandFilters
    : activeFiltersState?.brandFilters || [];

  if (category && !amenityFilters.includes(category)) {
    amenityFilters.push(category);
  }

  if (!showSaleFilter && attributeFilters.includes(HotelAttributeIds.sale)) {
    attributeFilters.splice(attributeFilters.indexOf(HotelAttributeIds.sale), 1);
  }

  if (brandCode && brandCode !== 'WW' && !brandFilters.includes(brandCode)) {
    brandFilters.push(brandCode);
  }
  const priceFilterState =
    sessionFiltersState?.priceFilter !== undefined
      ? sessionFiltersState?.priceFilter
      : activeFiltersState?.priceFilter;
  const priceFilter = datesFlex ? undefined : priceFilterState;
  const ratingsFilter = sessionFiltersState?.ratingsFilter
    ? sessionFiltersState?.ratingsFilter
    : activeFiltersState?.ratingsFilter;
  const showAvailableHotelsState =
    sessionFiltersState?.showAvailableHotels !== undefined
      ? sessionFiltersState.showAvailableHotels
      : activeFiltersState?.showAvailableHotels;
  const showAvailableHotels = datesFlex ? false : showAvailableHotelsState;

  return {
    ...activeFiltersState,
    amenityFilters,
    attributeFilters,
    brandFilters,
    priceFilter,
    ratingsFilter,
    showAvailableHotels,
  };
}

export const getDisplayPrice = (price?: number, currency?: ConversionCurrencyOptions) => {
  const formattedPrice = price?.toLocaleString();
  let displayPrice = `$${formattedPrice}`;
  if (currency?.currencySymbolFormat)
    displayPrice = currency?.currencySymbolFormat?.replace(/\{0\}/, `${formattedPrice}`);
  else if (currency?.currencyCode) displayPrice = `${formattedPrice} ${currency?.currencyCode}`;

  return displayPrice;
};
export const invalidPriceRange = (minimum: number, maximum: number) =>
  Number.isFinite(minimum) && Number.isFinite(maximum) && minimum > maximum;

const replaceNonDigits = (val: string) => {
  let value = val?.replace(/[\D\s._-]+/i, '');
  value = value?.replace(/,/g, '');
  return value;
};
export const getNumericPriceValue = (price: string) => {
  const priceVal = price || '';
  return parseFloat(replaceNonDigits(priceVal));
};

export function isNumeric(num: string) {
  return Number.isFinite(+num) && !isNaN(parseFloat(replaceNonDigits(num)));
}

export const formatPoints = (points?: number, lang?: string) =>
  points ? new Intl.NumberFormat(lang || DEFAULT_LANGUAGE).format(points) : '';

export const getConvertedMaxPrice = (
  activeFiltersState: Partial<ActiveFiltersState> | null = null,
  selectedCurrency = 'USD'
): string => {
  const priceFilter = activeFiltersState?.priceFilter ?? [];
  const priceIndex = !selectedCurrency || selectedCurrency === 'USD' ? 0 : 1;

  return String(priceFilter[priceIndex] ?? 'noMaximum');
};
