import { sendReward } from '@dx-ui/framework-conductrics';
import { FilterMaxPriceRadioButtons } from '@dx-ui/osc-filters';
import { TabButton, TabList, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import { GOALS } from '../../../constants';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

export const PricePlannerTabs = () => {
  const { t } = useTranslation('default-locations-page');
  const PRICE_LIST = [
    { basePrice: 100, convertedPrice: 100 },
    { basePrice: 200, convertedPrice: 200 },
    { basePrice: 350, convertedPrice: 350 },
    { basePrice: 500, convertedPrice: 500 },
  ];
  const POINTS_LIST = [
    { basePrice: 30000, convertedPrice: 30000 },
    { basePrice: 50000, convertedPrice: 50000 },
    { basePrice: 75000, convertedPrice: 75000 },
    { basePrice: 95000, convertedPrice: 95000 },
  ];
  const { setValue, watch } = useFormContext();
  const shouldUsePoints = watch('specialRates.redeemPts');
  const handleTabClick = (tabId: string) => {
    setValue('specialRates.redeemPts', tabId === 'points-explorer-tab');
    setValue('priceFilter', 'noMaximum');
    if (tabId === 'points-explorer-tab') {
      sendReward(GOALS.planYourStayTabClick);
    }
  };
  return (
    <Tabs>
      <TabList className="mb-4">
        <TabButton
          id="price-planner-tab"
          className="hover:text-primary focus:text-primary ml-4 grow cursor-pointer appearance-none whitespace-nowrap px-4 py-2 text-base font-bold md:whitespace-normal"
          activeClassName="border-b-primary text-primary border-b-4"
          inactiveClassName="text-text-alt"
          handleOnClick={() => handleTabClick('price-planner-tab')}
        >
          {t('pricePlanner.pricePlanner')}
        </TabButton>
        <div className="border-l-text-alt mb-1.5 border-l-2" />
        <TabButton
          id="points-explorer-tab"
          className="hover:text-primary focus:text-primary mr-4 grow cursor-pointer appearance-none whitespace-nowrap px-4 py-2 text-base font-bold md:whitespace-normal"
          activeClassName="border-b-primary text-primary border-b-4"
          inactiveClassName="text-text-alt"
          handleOnClick={() => handleTabClick('points-explorer-tab')}
        >
          {t('pricePlanner.pointsExplorer')}
        </TabButton>
      </TabList>
      <TabPanels>
        {!shouldUsePoints ? (
          <TabPanel id="price-planner-tab">
            <div className="mb-2 text-base font-bold"> {t('pricePlanner.priceFilterHeading')}</div>
            <FilterMaxPriceRadioButtons currencyCode="USD" priceList={PRICE_LIST} />
          </TabPanel>
        ) : null}
        {shouldUsePoints ? (
          <TabPanel id="points-explorer-tab">
            <div className="mb-2 text-base font-bold">{t('pricePlanner.priceFilterHeading')}</div>
            <FilterMaxPriceRadioButtons currencyCode="USD" priceList={POINTS_LIST} isPointsFilter />
          </TabPanel>
        ) : null}
      </TabPanels>
    </Tabs>
  );
};
